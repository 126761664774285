globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"7Ptq8kS1qVkS8UFHV9A2H"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs"

Sentry.init({
  environment: process.env.NEXT_NODE_ENV,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreErrors: [
    "Can't find variable: gmo",
    "qismoConfig is not defined",
    "Can't find variable: qismoConfig",
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
  ],
})
